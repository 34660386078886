import React from "react"
import Spinner from "../components/spinner";
import { format, startOfWeek, parseISO } from "date-fns";
import seedrandom from 'seedrandom'

import "./poll.scss";
import { HeartToggle, ThumbsUpToggle } from "../components/inputs";
import { useAppContext } from "../context";
import { FaRegHeart, FaRegHandshake, FaInstagram } from "react-icons/fa";
import * as graphql from "../apollo/graphql";
import Media from '../components/media'

const Submission = ({
  poll_id,
  submission,
  insertVote,
  deleteVote,
  refetch
}) => {
  const app = useAppContext();

  const [
    insertUserSubmitterRecommendation
  ] = graphql.useInsertUserSubmitterRecommendationMutation();
  const [
    deleteUserSubmitterRecommendation
  ] = graphql.useDeleteUserSubmitterRecommendationMutation();

  const isVotedFor = submission.votes_aggregate.aggregate.count == 1;
  const isRecommended = submission.submitter.recommendations.length == 1;

  const insertRecommendation = () => {
    insertUserSubmitterRecommendation({
      variables: {
        user_id: app.user?.id,
        poll_id,
        submitter_id: submission.submitter.id
      }
    }).then(() => refetch());
  };

  const deleteRecommendation = () => {
    deleteUserSubmitterRecommendation({
      variables: {
        user_id: app.user?.id,
        poll_id,
        submitter_id: submission.submitter.id
      }
    }).then(() => refetch());
  };

  return (
    <div className="card mb-4 box-shadow submission" data-id={submission.id}>
      <div className="card-img-top">
        <a href={submission.url} target="_blank" rel="noopener noreferrer">
          <Media image_url={submission.image_url} video_url={submission.video_url} />
        </a>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="list-inline d-flex justify-content-between">
            <div className="list-inline-item">
              <HeartToggle
                toggled={isVotedFor}
                title="Vote"
                onClick={() => {
                  isVotedFor
                    ? deleteVote(submission.id)
                    : insertVote(submission.id);
                }}
              />
            </div>
            <div className="list-inline-item">
              <ThumbsUpToggle
                toggled={isRecommended}
                title="Recommend to work at Buck"
                onClick={() => {
                  !isRecommended ? insertRecommendation() : deleteRecommendation();
                }}
              />
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="text-truncate">
            <a className="mr-2" href={submission.url} target="_blank" rel="noopener noreferrer" title="View on Instagram"><FaInstagram /></a>
            <a href={`https://www.instagram.com/${submission.username}/`} target="_blank" rel="noopener noreferrer" title="View user's profile on Instagram">@{submission.username}</a>
          </div>
        </li>
      </ul>
    </div>
  );
};

const EditorControls = ({ poll_id }: { poll_id: string }) => {
  const app = useAppContext();
  const test = () => {
    // @ts-ignore
    logs('yo');
    
  }
  const [closePoll] = graphql.useClosePollMutation({
    variables: { user_id: app.user?.id, poll_id },
    refetchQueries: [
      { query: graphql.ActivePollDocument },
      { query: graphql.UnassignedSubmissionsDocument }
    ]
  });
  return (
    <div>
      <button onClick={() => closePoll()} className="btn btn-warning ">
        Close Poll
      </button>
    </div>
  );
};

function shuffle(array: any[], seed: string) {
  const rng = seedrandom(seed)
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(rng() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default ({ poll_id }: { poll_id: string }) => {
  const app = useAppContext();

  const { data, loading, refetch } = graphql.usePollQuery({
    variables: { poll_id, user_id: app.user?.id },
    pollInterval: app.user?.role == "editor" ? 2000 : undefined
  });

  const [insertVoteMutation] = graphql.useInsertVoteMutation();
  const [deleteVoteMutation] = graphql.useDeleteVoteMutation();

  const insertVote = (submission_id: string) => {
    insertVoteMutation({
      variables: {
        submission_id,
        user_id: app.user?.id,
        poll_id: data.poll.id
      }
    }).then(() => refetch());
  };

  const deleteVote = (submission_id: string) => {
    deleteVoteMutation({
      variables: {
        submission_id,
        user_id: app.user?.id,
        poll_id: data.poll.id
      }
    }).then(() => refetch());
  };

  if (loading || !data || !data.poll)
    return (
      <div className="jumbotron">
        <div className="container">
          <Spinner />
        </div>
      </div>
    );
    
  let shuffledOptions = data.poll.options.nodes
  try {
    shuffledOptions = shuffle([...data.poll.options.nodes], app.user?.email)
  } catch (error) {
    
  }

  return (
    <div className="poll" data-id={data.poll.id}>
      <div className="jumbotron">
        <div className="container">
          <h1 className="jumbotron-heading">Poll</h1>
          <p>{format(parseISO(data.poll.created_at), "PP")}</p>
          <p className="lead text-muted">
            There are <strong>{data.poll.options.aggregate.count}</strong>{" "}
            submissions to vote on this week. Click <FaRegHeart /> to vote for
            submissions you like. Click <FaRegHandshake /> to recommend that
            submitter as a good candidate for working with Buck. Submissions with video will automatically play when hovered over.
          </p>
          {app.user && app.user.role == "editor" && <EditorControls poll_id={poll_id} />}
        </div>
      </div>
      <div className="poll-body content-inner bg-light">
        <div className="container submissions">
          {shuffledOptions.map(({ submission }) => {
            return (
              <Submission
                key={submission.id}
                poll_id={poll_id}
                submission={submission}
                insertVote={insertVote}
                deleteVote={deleteVote}
                refetch={refetch}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
