import React from "react"
import {
  FaToggleOn,
  FaToggleOff,
  FaHandshake,
  FaRegHandshake,
  FaHeart,
  FaRegHeart,
} from "react-icons/fa"

export const ActiveToggle: React.SFC<
  { toggled: boolean } & React.HTMLAttributes<HTMLDivElement>
> = ({ toggled = false, ...props }) => {
  return (
    <div className="toggle" {...props}>
      {toggled ? <FaToggleOn /> : <FaToggleOff />}
    </div>
  )
}

export const ThumbsUpToggle: React.SFC<
  { toggled: boolean } & React.HTMLAttributes<HTMLDivElement>
> = ({ toggled = false, ...props }) => {
  return (
    <div className="toggle thumbs-up" {...props}>
      {toggled ? <FaHandshake /> : <FaRegHandshake />}
    </div>
  )
}

export const HeartToggle: React.SFC<
  { toggled: boolean } & React.HTMLAttributes<HTMLDivElement>
> = ({ toggled = false, ...props }) => {
  return (
    <div className="toggle heart" {...props}>
      {toggled ? <FaHeart /> : <FaRegHeart />}
    </div>
  )
}
