import React from "react"
import { Link } from "gatsby"
import Layout from "../containers/layout"
import Private from "../components/private"
import { useAppContext } from "../context"
import Spinner from '../components/spinner'
import CreatePoll from '../components/create-poll'
import Poll from '../containers/poll'
import * as graphql from '../apollo/graphql'

const PollPage = () => {
  const app = useAppContext();

  const pollQuery = graphql.useActivePollQuery({ pollInterval: 10000 });

  if (pollQuery.loading)
    return (
      <div className="jumbotron">
        <div className="container">
          <Spinner />
        </div>
      </div>
    );

  const pollId = pollQuery.data.poll[0] && pollQuery.data.poll[0].id;

  if (!pollId) {
    if (app.user?.role == "user") {
      return (
        <div className="jumbotron">
          <div className="container">
            <h1 className="jumbotron-heading">Poll</h1>
            <p className="lead">No open poll. Please check back later!</p>
            <Link className="btn btn-secondary" to="/results">
              <a >View Previous Results</a>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="jumbotron">
            <div className="container">
              <h1 className="jumbotron-heading">Poll</h1>
              <p className="lead">No open poll.</p>
              <Link className="btn btn-secondary" to="/results">
                View Previous Results
              </Link>
            </div>
          </div>
          <CreatePoll />
        </>
      );
    }
  } else {
    return (
      <Poll poll_id={pollId} />
    );
  }
}

export default () => (
  <Private>
    <Layout>
      <PollPage />
    </Layout>
  </Private>
);