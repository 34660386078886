import React from "react"
import * as inputs from "../components/inputs"
import * as graphql from "../apollo/graphql"
import { v4 as uuid } from "uuid"
import Media from '../components/media'
import Spinner from "../components/spinner"
import { format, parseISO } from "date-fns"
import { FaInstagram, FaRegClock, FaSearch } from "react-icons/fa"
import { useAppContext } from "../context"

const Submission = ({ submission }) => {
  const [disableSubmission] = graphql.useSetSubmissionStatusMutation({
    refetchQueries: [{ query: graphql.UnassignedSubmissionsDocument }],
  })
  const takenAt = format(parseISO(submission.taken_at), "PP")
  const addedAt = format(parseISO(submission.created_at), "PP")
  return (
    <div className="card box-shadow">
      <div className="card-img-top">
        <Media image_url={submission.image_url} video_url={submission.video_url} />
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="text-truncate">
            <a className="mr-2" href={submission.url} target="_blank" rel="noopener noreferrer" title="View on Instagram"><FaInstagram /></a>
            <a href={`https://www.instagram.com/${submission.username}/`} target="_blank" rel="noopener noreferrer" title="View user's profile on Instagram">@{submission.username}</a>
          </div>
        </li>
        <li className="list-group-item">
          <div className="text-truncate d-flex align-items-center" title={`Created on ${takenAt}`}>
            <FaRegClock className="mr-2" /> {takenAt}
          </div>
          <div className="text-truncate d-flex align-items-center" title={`Added to BuckUBack on ${addedAt}`}>
            <FaSearch className="mr-2" /> {addedAt}
          </div>
        </li>
        <li className="list-group-item">
          <span className="extra-small">{submission.id}</span>
        </li>
        <li className="list-group-item">
          <button className={`btn btn-sm ${submission.is_active ? "btn-secondary" : "btn-success"}`} onClick={() =>
            disableSubmission({
              variables: {
                submission_id: submission.id,
                active: !submission.is_active
              }
            })
          }>{submission.is_active ? "Disable" : "Enable"}</button>
        </li>
      </ul>
    </div>
  )
}

export default () => {
  const app = useAppContext();

  const [createNewPollMutation] = graphql.useCreateNewPollMutation()
  const unassignedSubmissionsQuery = graphql.useUnassignedSubmissionsQuery({
    pollInterval: app.user?.role == "editor" ? 2000 : undefined
  })

  const numberOfUnassignedSubmissions =
    unassignedSubmissionsQuery.data &&
    unassignedSubmissionsQuery.data.unassigned_submissions.length

  const createNewPoll = () => {
    const poll_id = uuid()
    const submission_options = unassignedSubmissionsQuery.data.unassigned_submissions.map(
      unassigned_submission => {
        return {
          poll_id: poll_id,
          submission_id: unassigned_submission.id,
          is_active: unassigned_submission.is_active,
        }
      }
    )
    createNewPollMutation({
      variables: {
        poll_id,
        submission_options,
      },
      refetchQueries: [{ query: graphql.ActivePollDocument }],
    })
  }

  if (unassignedSubmissionsQuery.loading)
    return (
      <div className="jumbotron">
        <div className="container">
          <Spinner />
        </div>
      </div>
    )

  return (
    <div className="content-inner bg-light">
      <div className="container">
        <div className="mb-4">
          <h3>Create a New Poll</h3>
          <p>
            There are {numberOfUnassignedSubmissions} unassigned submissions. Exclude submissions by clicking on the disable button before.
          </p>
          <button
            onClick={createNewPoll}
            className="btn btn-primary btn-lg"
            disabled={numberOfUnassignedSubmissions == 0}
          >
            Create Poll
          </button>
        </div>

        <div className="submissions mb-4">
          {unassignedSubmissionsQuery.data &&
            unassignedSubmissionsQuery.data.unassigned_submissions.map(
              unassigned_submission => {
                return (
                  <Submission
                    key={unassigned_submission.id}
                    submission={unassigned_submission}
                  />
                )
              }
            )}
        </div>

      </div>
    </div>
  )
}
